
import { BottomNavigation, BottomNavigationAction, Card, CardContent, Modal } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { LetsGetStarted } from '../LetsGetStarted';
import { Code } from '@mui/icons-material';

export const FooterLearn = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Card sx={{ maxWidth: 400, transform: 'translate(-50%,-50%)', top: '50%', left: '50%', position: 'absolute' }}>
          <CardContent>
            <h1>Get in Touch</h1>
            <LetsGetStarted variant={'learn'} />
          </CardContent>
        </Card>
      </Modal>
      <BottomNavigation showLabels>

        <BottomNavigationAction label="Home" icon={<HomeIcon />} component={Link} to="/learn" />
        <BottomNavigationAction label="Coders" icon={<Code />} component={Link} to="/coders" />
        <BottomNavigationAction label="Contact" icon={<ContactMailIcon />} onClick={() => { setShowModal(true) }} />
      </BottomNavigation>
    </>
  );
}
