import React, { useEffect, useState } from 'react';
import { LearnHeader } from '../../Header/LearnHeader';
import { FooterLearn } from '../../Footer/FooterLearn';
import { useLocation } from 'react-router-dom';
import { Avatar, Button, Card, CardActions, CardContent, CardMedia, Container, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@mui/material';
import { ICourse, courses } from './coursesdata';
import { ILocationState } from '../../../utils/types/location/state';
import { Helmet } from 'react-helmet';

export const Course = () => {
    const { state } = useLocation();

    const [course, setCourse] = useState<ICourse>({ id: 0 });
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        if (state && state.courseId && state.course) {
            setCourse(state.course);
            setIsLoaded(true);
        }
    }, [])
    return (
        <>

            <LearnHeader />
            {!isLoaded && 'Loading...'}
            {isLoaded && <Container sx={{ mt: 3, padding: 3 }}>
                <Helmet>
                <meta charSet="utf-8" />
                <title>Splay technologies learning centre - {course.title}</title>
                </Helmet>
                <Paper elevation={3} sx={{ padding: 3 }}>
                    <Typography variant="h4" gutterBottom>
                        {course.title}
                    </Typography>
                    <Typography sx={{padding: 2}} variant="h5" gutterBottom >
                        {course.description}
                    </Typography>
                    {/* <CardMedia
                                            sx={{ height: 450 }}
                                            image={"/"+course.image}
                                        /> */}
                    
                        {course.chapters && course.chapters.map((chapter, index) => {
                            return (
                                <>
                                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                        <ListItem>
                                            <ListItemText
                                                primary={`Chapter ${index + 1} - ${chapter.title}`}
                                                primaryTypographyProps={{
                                                    fontSize: 20,
                                                    fontWeight: 'medium',
                                                    letterSpacing: 1,
                                                  }}
                                                secondary={chapter.description}
                                            />
                                        </ListItem>
                                        <Divider variant="fullWidth" component="li" />
                                    </List>
                                </>
                            )
                        })}
                        {/* Add more expertise sections as needed */}
                        {!course.chapters && <Typography variant="h5" gutterBottom>
                            Course details coming soon...
                        </Typography>}
                </Paper>
            </Container>}


            <FooterLearn />
        </>
    )
}