import React, { useState } from 'react';
import { Container, Paper, Typography, TextField, Button, Grid, List, ListItem, ListItemText, LinearProgress, Alert } from '@mui/material';
import { Formik, Field, FormikValues, FormikHelpers, useFormik } from 'formik';
import { EmailTxtFld, HearAbtUsTxtFld, NameTxtFld, ProjectDescTxtFld } from './comps';
import { ILetsGetStartedVariant, initialValues, validationSchema } from './businessRules';
import { createLead } from '../../utils/firebase/firebase';
import { CheckCircleRounded } from '@mui/icons-material';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

export interface IPropsLetsGetStarted {
    isSubmitting: any;
    submitForm: any;
}



export const LetsGetStarted = ({variant = 'default'}: {variant?: ILetsGetStartedVariant}) => {
    const [successSubmit, setSuccessSubmit] = useState(false);
    const [fullName, setName] = useState('');
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting, resetForm }: FormikHelpers<FormikValues>) => {
            setTimeout(async () => {
                setSubmitting(false);
                // alert(JSON.stringify(values, null, 2));
                try {
                    const result = await createLead(values);
                    if (result) {
                        setName(values.name)
                        setSuccessSubmit(true);
                        resetForm();
                        setTimeout(()=>{
                            setSuccessSubmit(false)
                            setName('');
                        }, 5000)
                    }
                    else {
                        console.log('error');
                    }
                } catch (err) {
                    console.log(err);
                }
            }, 500);
        }
    });

    return <>
        <form onSubmit={formik.handleSubmit}>
            {successSubmit && <Alert icon={<CheckCircleRounded fontSize="inherit" />} severity="success">
                We have received your request {fullName}, our team will get back to you. <SentimentSatisfiedAltIcon/>
            </Alert>}
            <NameTxtFld formik={formik} />
            <EmailTxtFld formik={formik} />
            <ProjectDescTxtFld formik={formik} variant={variant} />
            {/* <HearAbtUsTxtFld formik={formik} /> */}
            <Button color="primary" variant="contained" fullWidth type="submit">
                Submit
            </Button>
        </form>

    </>
}