import { TextField } from "@mui/material"
import { FormikConfig, FormikValues, useFormik } from "formik"
import { variants, ILetsGetStartedVariant } from "./businessRules"

export const NameTxtFld = ({formik}: any) => {
    return <TextField fullWidth id='name' label='Name' value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.name && Boolean(formik.errors.name)}
        margin='dense'
        helperText={formik.errors.name?.toString()}
    />
}
export const EmailTxtFld = ({formik}: any) => {
    return <TextField fullWidth id='email' label='Email' value={formik.values.email}
        required
        onChange={formik.handleChange}
        margin='dense'
        onBlur={formik.handleBlur}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.errors.email?.toString()}
    />
}

export const ProjectDescTxtFld = ({formik, variant = 'default'}: {formik: any, variant: ILetsGetStartedVariant}) => {
    return <TextField fullWidth id='projectDescription' label={variants[variant].ProjectDescTxtFld.label} value={formik.values.projectDescription}
        margin='dense'
        multiline
        minRows={4}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.projectDescription && Boolean(formik.errors.projectDescription)}
        helperText={formik.errors.projectDescription?.toString()}
    />
}

export const HearAbtUsTxtFld = (formik: any) => {
    return <TextField fullWidth id='hearAboutUs' label='How did you hear about us?' value={formik.values.hearAboutUs}
        margin='dense'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.hearAboutUs && Boolean(formik.errors.hearAboutUs)}
    />
}