import React from "react";
import { LearnHeader } from "../Header/LearnHeader";
import { FooterLearn } from "../Footer/FooterLearn";
import { Container } from "@mui/material";

export const CoderSaurabh = () => {
    return (
        <>
            <LearnHeader />
            <Container>
            <h1>Saurabh</h1>
            </Container>
            
            <FooterLearn />
        </>
    )
}