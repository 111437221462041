// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { addDoc, collection, serverTimestamp, getFirestore, doc, setDoc } from "firebase/firestore";
import {v4 as uuidV4} from 'uuid';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD42YmzFT2fB9w4WlU9xRJiCmQjBeW9Umk",
  authDomain: "splay-tech-web-app.firebaseapp.com",
  projectId: "splay-tech-web-app",
  storageBucket: "splay-tech-web-app.appspot.com",
  messagingSenderId: "960995107471",
  appId: "1:960995107471:web:38ba9fbd6e014e8c6ac6fa",
  measurementId: "G-P5QGLFXT3F",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

const whitelistRegex: RegExp = /^([a-zA-Z0-9 @.\n]{2,50})$/;
const whitelistRegexDescription: RegExp = /^[A-Za-z0-9. @\n]{5,1000}$/;
const whitelistRegexEmail: RegExp = /^([a-zA-Z0-9 @.\n]{6,100})$/;

export const createLead = async (lead: any): Promise<boolean> => {
  const leadsCollection = collection(db, "splay-wa-leads-req");
  if (
    whitelistRegex.test(lead.name) &&
    whitelistRegexEmail.test(lead.email) &&
    whitelistRegexDescription.test(lead.projectDescription)
  ) {
    try {
      const docRef = doc(db, 'splay-wa-leads-req', uuidV4())
      await setDoc(docRef, {
        name: lead.name,
        email: lead.email,
        projectBrief: lead.projectDescription,
        timestamp: serverTimestamp()
      });
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  } 
  return Promise.reject('Invalid input');
};
// const analytics = getAnalytics(app);
