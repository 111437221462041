import React from "react";
import { LearnHeader } from "../Header/LearnHeader";
import { FooterLearn } from "../Footer/FooterLearn";
import { Button, Card, CardActions, CardContent, Chip, Container, Grid, List, ListItem, Paper, Typography } from "@mui/material";
import { CoderSaurabh } from "./saurabh";
import { Link, useNavigate } from "react-router-dom";


export const CodersHome = () => {
    const navigate = useNavigate();
    return (
        <>
            <LearnHeader />
            <Container sx={{ mt: 3, padding: 3 }}>

                <Paper elevation={3} sx={{ padding: 5 }}>
                    <Typography variant="h4" gutterBottom>
                        Our Coders
                    </Typography>

                    <Grid container spacing={3} sx={{ paddingTop: 2 }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ maxWidth: 500, boxShadow: 2, borderRadius: 2, p: 2 }}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        Carina
                                    </Typography>
                                    <Typography paragraph>
                                        All about Carina
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={() => { navigate('/coders/carina') }}>About me</Button>
                                </CardActions>
                            </Card>
                        </Grid>

                    </Grid>
                </Paper>

            </Container>
            <FooterLearn />
        </>
    )
}
