import * as yup from "yup";

export const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  name: yup.string().required("Name is required"),
  hearAboutUs: yup.string(),
  projectDescription: yup
    .string()
    .min(5, "Please provide some brief description in few words")
    .required("Please provide some brief description"),
});

export const initialValues = {
  name: "",
  email: "",
  projectDescription: "",
  hearAboutUs: "",
};


export const variants = {
    default: {
        ProjectDescTxtFld: {
            label: 'Project Brief'
        }
    },
    learn: {
        ProjectDescTxtFld: {
            label: 'Your message'
        }
    }
}
export type ILetsGetStartedVariant = 'default' | 'learn';
