import { Container, Card, Paper, Typography, Grid, List, ListItem, ListItemText, CardMedia, CardContent, Modal, Button, CardActions, Chip, Avatar, Fab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LetsGetStarted } from "../LetsGetStarted";
import { LearnHeader } from "../Header/LearnHeader";
import { FooterLearn } from "../Footer/FooterLearn";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {Helmet} from "react-helmet";
import { courses } from "./Courses/coursesdata";
import { useLocation, useNavigate  } from "react-router-dom";



export const LearnSplayTech = () => {
    const [showModal, setShowModal] = useState(false);
    const {state, key} = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // if (process.env.NODE_ENV != 'development') {
        //     setTimeout(() => {
        //         setShowModal(true);
        //     }, 10 * 1000)
        // }
    }, []);
    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Splay technologies learning centre</title>
        </Helmet>
        <LearnHeader />
        <Container sx={{ mt: 3, padding: 3 }}>
            <Fab color='primary' aria-label='chat' sx={{position: "fixed", right:20, top: 'auto',left:'auto', bottom:20}} href="https://wa.me/message/YVRCKA6FFD76M1">
                <WhatsAppIcon />
            </Fab>
            <Paper elevation={3} sx={{ padding: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Courses we offer
                </Typography>

                <Grid container spacing={3} sx={{ mt: 2 }}>
                    {courses.map(course => {
                        return (
                            <>
                                {course.enabled && <Grid item xs={12} md={6}>
                                    <Card sx={{ maxWidth: 500, boxShadow: 2, borderRadius: 2, p: 2 }}>
                                        <CardMedia
                                            sx={{ height: 300 }}
                                            image={"/"+course.image}
                                        />

                                        <CardContent>
                                            <Typography variant="h5" gutterBottom>
                                                {course.title}
                                            </Typography>
                                            <Typography paragraph>
                                                {course.brief}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Chip  color="info" label={`$ ${course.courseFee}`} variant="outlined" />
                                            <Button size="small" onClick={()=> {navigate('/course', {state: {courseId: course.id, course: course}})}}>Learn More</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>}
                            </>
                        )
                    })}

                    {/* Add more expertise sections as needed */}
                </Grid>

                <Modal open={showModal} onClose={() => setShowModal(false)}>
                    <Card sx={{ maxWidth: 400, transform: 'translate(-50%,-50%)', top: '50%', left: '50%', position: 'absolute' }}>
                        <CardContent>
                            <h1>Get in Touch</h1>
                            <LetsGetStarted variant={'learn'} />
                        </CardContent>
                    </Card>
                </Modal>

            </Paper>
            <br />
            <Paper elevation={3} sx={{ padding: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Our Mission
                </Typography>

                <Grid container spacing={3} sx={{ mt: 2 }}>
                    <Grid item xs={12} md={4}>
                        <CardMedia
                            sx={{ height: 300, borderRadius: 4, boxShadow: 2 }}
                            image="/mission.jpeg"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CardContent>
                            <Typography paragraph>
                                Online courses aim to bridge the gap between education and future needs.  By providing engaging, accessible learning experiences, we empower students of all ages to develop in-demand skills and foster a love for lifelong learning, preparing them to thrive in an ever-evolving world.
                            </Typography>
                        </CardContent>

                    </Grid>
                </Grid>
            </Paper>
            <br />
            <Paper elevation={3} sx={{ padding: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Our Vision
                </Typography>

                <Grid container spacing={3} sx={{ mt: 2 }}>
                    <Grid item xs={12} md={4}>
                        <CardMedia
                            sx={{ borderRadius: 4, boxShadow: 2, maxHeight: 400, height: 300 }}
                            image="/vision.jpeg"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CardContent>
                            <Typography paragraph>
                                To empower students. Imagine interactive, age-appropriate platforms that spark curiosity and equip kids with future-ready skills. Through engaging online learning, students will develop critical thinking, problem-solving, and collaboration – all while building a love for lifelong learning.
                            </Typography>
                        </CardContent>

                    </Grid>
                </Grid>
            </Paper>
        </Container>
        <FooterLearn />
    </>
}