// App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';
import {Home} from './components/Home';
import {ContactUs} from './components/ContactUs';
import {AboutUs} from './components/AboutUs';
import {OurValues} from './components/OurValues';
import {OurTeam} from './components/OurTeam';
import { LearnSplayTech } from './components/Learn/learn';
import { Course } from './components/Learn/Courses/Course';
import { CoderSaurabh } from './components/coders/saurabh';
import { CodersHome } from './components/coders/CodersHome';
import { CoderCarina } from './components/coders/carina';

function App() {
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/values" element={<OurValues />} />
        <Route path="/team" element={<OurTeam />} />
        <Route path="/learn" element={<LearnSplayTech />} />
        <Route path="/course" element={<Course />} />
        <Route path="/coders" element={<CodersHome />} />
        <Route path="/coders/saurabh" element={<CoderSaurabh />} />
        <Route path="/coders/carina" element={<CoderCarina />} />
      </Routes>
    </Router>
  );
}

export default App;
