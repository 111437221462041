import { title } from "process";

export const courses: ICourse[] = [
  {
    enabled: true,
    title: "Code Explorers: Adventures in Block Coding!",
    brief:
      "A fun and interactive course for young kids to learn coding through block-based programming with Scratch, creating animations, games, and stories.",
    id: 1,
    description: `"Code Explorers: Adventures in Block Coding!" is a delightful and interactive course tailored for young kids to dive into the basics of coding through block-based programming. Using platforms like Scratch, children will create their own animations, games, and stories by snapping together colorful code blocks. This course nurtures creativity, problem-solving skills, and logical thinking in a playful environment. Perfect for budding coders, it provides a foundation for understanding programming concepts while igniting their passion for technology.`,
    image: "code_block.jpeg",
    courseFee: "249",
    chapters: [
      {
        title: "Scratch Jr: Getting to Know the Interface",
        description:
          "Dive into Scratch Jr and get familiar with how it works! You'll learn to navigate the screen, use sprites, choose backgrounds, and start your journey in visual programming.",
      },
      {
        title: "Scratch Jr: Create a Driving Game",
        description:
          "Jump into action by making your own driving game in Scratch Jr! You'll have fun programming cars to move across the city and see how they interact with different objects.",
      },
      {
        title: "Scratch Jr: Make Your Own Interactive Stories",
        description:
          "Bring your stories to life with Scratch Jr! Learn how to create exciting animations and sequences using block-based coding to tell your own unique tales.",
      },
      {
        title: "Scratch Jr: Mix Sounds and Animation",
        description:
          "Add a new dimension to your projects by combining cool sound effects with awesome animations. You'll learn how to make your Scratch Jr creations even more engaging!",
      },
      {
        title: "Block Coding: Start Here!",
        description:
          "Begin your adventure in block-based coding! Discover the basic ideas and logic behind visual programming and see how you can start building simple programs.",
      },
      {
        title: "Block Coding: Turtle Graphics Fun",
        description:
          "Use block coding to draw amazing geometric shapes and patterns with a virtual turtle. This activity helps you understand how programming can create art and solve puzzles.",
      },
      {
        title: "Block Coding: Maze Challenges",
        description:
          "Challenge yourself by coding solutions to navigate through mazes. Learn how to use block coding to solve problems and improve your logical thinking skills.",
      },
      {
        title: "Block Coding: Create a Catching Game",
        description:
          "Build your own exciting game where you catch objects as they fall. You'll use block coding to set up game mechanics, track scores, and create fun interactions.",
      },
      {
        title: "Block Coding: Design a Virtual Pet",
        description:
          "Create and care for your own virtual pet using block coding. You'll program behaviors, animations, and sounds to make your pet come to life!",
      },
    ],
  },
  {
    enabled: true,
    id: 6,
    title: "AI Fundamentals for Young Learners: Usage and Ethics",
    image: "AICourse.jpeg",
    courseFee: "249",
    brief:
      "A comprehensive course introducing AI: Learn effective usage, prompt crafting, applications, and ethical considerations for young learners.",
    description:
      "This course introduces young learners to the fascinating world of Artificial Intelligence (AI). It covers the basics of AI, practical applications using tools like ChatGPT and Google Assistant, and how to use Google Gemini efficiently. Students will learn how to write and speak prompts for AI to generate accurate responses and understand the ethical considerations of using AI.",
    chapters: [
      {
        title: "What is AI?",
        description:
          "Introduction to Artificial Intelligence, its definition, and basic concepts.",
      },
      {
        title: "History and Evolution of AI",
        description:
          "Exploring the history and development of AI over the years.",
      },
      {
        title: "Everyday Examples of AI",
        description:
          "Identifying AI in everyday life and understanding its applications.",
      },
      {
        title: "Introduction to ChatGPT",
        description:
          "Overview of ChatGPT, its capabilities, and practical examples.",
      },
      {
        title: "Interacting with ChatGPT",
        description:
          "How to use ChatGPT effectively, including hands-on exercises.",
      },
      {
        title: "Introduction to Google Assistant",
        description: "Understanding Google Assistant and its features.",
      },
      {
        title: "Using Google Assistant Efficiently",
        description:
          "Practical tips for using Google Assistant, including real-world examples.",
      },
      {
        title: "Introduction to Google Gemini",
        description: "Overview of Google Gemini and its applications.",
      },
      {
        title: "Crafting Effective Prompts",
        description:
          "How to write and speak prompts for AI to generate accurate responses.",
      },
      {
        title: " Ethical Considerations of AI Use",
        description:
          "Guidelines on the responsible use of AI and understanding what not to do with AI.",
      },
    ],
  },
  {
    enabled: true,
    id: 4,
    image: "office.jpeg",
    courseFee: "199",
    title:
      "Digital Skills Mastery: Microsoft Office and Google Workspace Essentials",
    description: `"Digital Skills Mastery: Microsoft Office and Google Workspace Essentials" is designed to provide students with essential skills for today’s digital world. This course covers the key functionalities of Microsoft Office (Word, Excel, PowerPoint) and Google Workspace (Docs, Sheets, Slides, Drive). Participants will learn to create, edit, and collaborate on documents, spreadsheets, and presentations, enhancing their productivity and efficiency. Ideal for beginners, this course offers practical, hands-on experience with these vital tools, preparing students for academic and professional success in a digitally-driven environment.`,
    brief:
      "Essential skills in Microsoft Office and Google Workspace, covering document creation, spreadsheet management, and presentations, for academic and professional success.",
    chapters: [
      {
        title: "Understanding Computers and Their Components",
        description:
          "Introduction to the basic components of a computer system, including hardware components (CPU, memory, storage) and software components (operating system, applications).",
      },
      {
        title: "Microsoft Word: Creating Documents",
        description:
          "Learn how to create, format, and edit documents using Microsoft Word, including basic text formatting, inserting images, and managing document layouts.",
      },
      {
        title: "Microsoft PowerPoint: Creating Presentations",
        description:
          "Explore how to create engaging presentations using Microsoft PowerPoint, including adding slides, applying themes, inserting multimedia elements, and delivering presentations.",
      },
      {
        title: "Microsoft Excel: Creating Worksheets",
        description:
          "Introduction to creating and formatting spreadsheets in Microsoft Excel, including entering data, applying formulas, and managing worksheet layouts.",
      },
      {
        title: "Microsoft Excel: Using Functions",
        description:
          "Learn how to use built-in functions in Microsoft Excel for calculations, data analysis, and automating tasks, including functions like SUM, IF, and VLOOKUP.",
      },
      {
        title: "Microsoft Excel: Creating Charts",
        description:
          "Explore how to create various types of charts and graphs in Microsoft Excel to visualize data, including bar charts, pie charts, and line graphs.",
      },
      {
        title: "Google Docs and Google Slides",
        description:
          "Learn how to create and collaborate on documents and presentations using Google Docs and Google Slides, including formatting, sharing, and real-time collaboration features.",
      },
      {
        title: "Google Sheets: Spreadsheet Basics",
        description:
          "Introduction to creating and working with spreadsheets in Google Sheets, including entering data, applying formulas, and formatting cells.",
      },
    ],
  },
  {
    enabled: true,
    title: "Web Wizards: JavaScript and HTML for Beginners!",
    id: 2,
    courseFee: "599",
    image: "web_js_html.jpeg",
    description: `"Web Wizards: JavaScript and HTML for Beginners!" is an engaging course designed to Introduction to students to the fundamentals of web development. Participants will learn how to structure and style web pages using HTML and bring them to life with JavaScript. Through hands-on projects, students will create their own websites, adding interactive elements and visual appeal. This course is ideal for beginners eager to explore the world of web development, offering practical skills and inspiring creativity in building and coding for the web.`,
    brief:
      "An exciting course for beginners to start their web development journey, learning to build and style web pages using HTML and JavaScript.",
    chapters: [
      {
        title: "Introduction to HTML 5",
        description:
          "Learn the basics of HTML 5, including structure, elements, and semantic markup for creating web pages.",
      },
      {
        title: "Introduction to Styling",
        description:
          "Explore the fundamentals of CSS for styling HTML elements, including selectors, properties, and basic styling techniques.",
      },
      {
        title: "CSS Basics: Colors, Fonts, and Layouts",
        description:
          "Dive deeper into CSS by learning about colors, fonts, and layout techniques to enhance the appearance of web pages.",
      },
      {
        title: "Introduction to Javascript",
        description:
          "Get started with JavaScript, including basic syntax, variables, data types, and using JavaScript in web development.",
      },
      {
        title: "Variables in Javascript",
        description:
          "Learn how to declare and use variables in JavaScript, including different data types and variable scope.",
      },
      {
        title: "Running and Testing Javascript in Browser",
        description:
          "Explore how to run and test JavaScript code directly in web browsers, including debugging techniques.",
      },
      {
        title: "Understanding Arrays",
        description:
          "Introduction to arrays in JavaScript, understanding how to create, initialize, and manipulate arrays to store and access multiple values.",
      },
      {
        title: "Working with Arrays - Create, populate, access",
        description:
          "Continue exploring arrays in JavaScript, covering methods to populate arrays with data and access elements based on index.",
      },
      {
        title: "Operators and Expressions",
        description:
          "Learn about operators and expressions in JavaScript, including arithmetic, comparison, logical operators, and their use in coding.",
      },
      {
        title: "Statements and code blocks",
        description:
          "Understand JavaScript statements and code blocks, including control flow statements like if, else, switch, and loops.",
      },
      {
        title: "Loops in Javascript",
        description:
          "Explore different types of loops in JavaScript (for, while, do-while) and how to use them for repetitive tasks.",
      },
      {
        title: "Conditions in Javascript",
        description:
          "Learn about conditional statements in JavaScript, including if, else if, else statements for decision-making in code.",
      },
      {
        title: "Event handling in Javascript",
        description:
          "Understand how to handle events in JavaScript, including event listeners and handling user interactions on web pages.",
      },
      {
        title: "Manipulating the DOM with Javascript",
        description:
          "Explore how to manipulate the Document Object Model (DOM) using JavaScript to dynamically update and interact with web page content.",
      },
      {
        title: "Connecting the dots - HTML, CSS and JS",
        description:
          "Integrate HTML, CSS, and JavaScript to build interactive web pages, combining structure, style, and functionality.",
      },
      {
        title: "Build a portfolio page",
        description:
          "Apply HTML, CSS, and JavaScript skills to design and create a portfolio page showcasing projects and skills.",
      },
      {
        title: "Modern JS Frameworks - Angular, React",
        description:
          "Introduction to popular JavaScript frameworks like Angular and React, their features, and benefits for web development.",
      },
      {
        title: "Getting started with React - Create react app",
        description:
          "Learn the basics of React.js by creating a new React application using the create-react-app tool and setting up the development environment.",
      },
      {
        title: "React concepts - Components, State and Props",
        description:
          "Understand React.js concepts such as components, state, and props, and how they enable building reusable and dynamic user interfaces.",
      },
      {
        title: "Create portfolio page in react",
        description:
          "Apply React.js knowledge to build a portfolio page, utilizing components, state management, and JSX to create a responsive and interactive UI.",
      },
      {
        title: "Update portfolio page in react",
        description:
          "Learn how to update and enhance a React-based portfolio page, including adding new features, improving UI/UX, and optimizing performance.",
      },
      {
        title: "Responsive Web Design",
        description:
          "Explore techniques and best practices for designing responsive web pages that adapt to various devices and screen sizes using HTML, CSS, and media queries.",
      },
      {
        title: "Publish react app on Internet",
        description:
          "Learn how to deploy a React application to the internet, including configuring hosting services, managing domain settings, and deploying the app.",
      },
      {
        title: "Advanced React Concepts",
        description:
          "Deepen your understanding of React.js with advanced concepts such as state management libraries, routing, hooks, and integrating with backend services.",
      },
    ],
  },
  {
    enabled: true,
    title: "Java Mastery: Dive into Object-Oriented Programming!",
    id: 3,
    courseFee: "999",
    image: "java.jpeg",
    description: `"Java Mastery: Dive into Object-Oriented Programming" is a comprehensive course focused on teaching the core principles of object-oriented programming (OOP) using Java. Students will delve into concepts such as classes, objects, inheritance, and polymorphism, applying these to build robust and scalable applications. With a focus on real-world applications and problem-solving, this course equips learners with the skills to design and implement complex systems in Java. It's perfect for those looking to deepen their understanding of programming and advance their software development capabilities.`,
    brief:
      "A comprehensive course teaching the core principles of object-oriented programming with Java, focusing on classes, objects, inheritance, and more.",
    chapters: [
      {
        title: "Java Fundamentals: Understanding Classes",
        description:
          "Discover how Java uses 'classes' as blueprints to build objects and bring your code to life. You'll learn how to create and use these classes to organize and structure your programs.",
      },
      {
        title: "Java Basics: Exploring Data Types",
        description:
          "Learn about the different types of data you can use in Java, like numbers, letters, and more. Understand how to choose the right data type to make your code work smoothly.",
      },
      {
        title: "Essential Functions: Building Blocks of Java",
        description:
          "Find out how to write functions (also called methods) that make your code more organized and efficient. You'll see how these building blocks help you create and reuse pieces of code easily.",
      },
      {
        title: "Static and Void: Key Method Modifiers",
        description:
          "Understand what 'static' and 'void' mean when you write methods. These keywords will help you control how your methods behave and interact in your programs.",
      },
      {
        title: "Math Operations in Java: Calculations and Beyond",
        description:
          "Dive into the world of math in Java. You'll learn how to perform calculations, use math functions, and work with numbers to solve problems and build cool applications.",
      },
      {
        title: "Crafting Objects: The Heart of Java",
        description:
          "Get hands-on experience creating and using objects in Java. These objects, built from classes, are essential for making your programs interactive and dynamic.",
      },
      {
        title: "Mastering Arrays and Lists: Part 1",
        description:
          "Start exploring arrays and lists in Java. These tools help you store and manage multiple pieces of data, making it easier to handle large sets of information in your programs.",
      },
      {
        title: "Mastering Arrays and Lists: Part 2",
        description:
          "Continue your journey with arrays and lists by learning more advanced techniques. Discover how to work with multi-dimensional arrays and make the most of lists with Java's powerful methods.",
      },
      {
        title: "Looping Through: Iteration Techniques",
        description:
          "Learn how to use loops to repeat actions in your code. Whether it's 'for', 'while', or 'do-while', loops will help you handle repetitive tasks efficiently.",
      },
      {
        title: "Decision Making: Using Conditions in Java",
        description:
          "Master the art of decision-making in your programs. Use 'if', 'else if', 'else', and 'switch' statements to control the flow of your code based on different conditions.",
      },
      {
        title: "Access Control: Public, Private, and Protected",
        description:
          "Explore how Java's access modifiers ('public', 'private', and 'protected') help you control who can use or see different parts of your code. It's like setting permissions for your classes and methods.",
      },
      {
        title: "Abstract Concepts: Abstraction in Java",
        description:
          "Understand how abstraction lets you create general templates for your code. With abstract classes and methods, you can define frameworks that other classes can use and customize.",
      },
      {
        title: "Securing Code: Encapsulation Techniques",
        description:
          "Learn how encapsulation helps you protect and organize your code by bundling data and methods into classes. This makes your programs more secure and easier to manage.",
      },
      {
        title: "Building Hierarchies: Inheritance in Java",
        description:
          "Discover how inheritance allows you to build a hierarchy of classes. By reusing code from parent classes in child classes, you can create complex, efficient programs.",
      },
      {
        title: "Dynamic Behavior: Polymorphism Explained",
        description:
          "Find out how polymorphism lets you use objects in different ways. This flexibility makes your programs more dynamic and adaptable to changes.",
      },
      {
        title: "Blueprints for Code: Interfaces in Java",
        description:
          "Explore how interfaces define methods that multiple classes can use. This helps you create flexible and versatile code that can work in various scenarios.",
      },
      {
        title: "Building with Constructors and 'This' Keyword",
        description:
          "Learn how constructors help you set up new objects and how the 'this' keyword lets you refer to the current object in your code. These tools are key to building effective Java programs.",
      },
      {
        title: "Clean Code: Java Naming Conventions",
        description:
          "Discover why following naming conventions makes your code easier to read and maintain. Learn the best practices for naming variables, methods, and classes in Java.",
      },
      {
        title: "Understanding Computer Memory: Concepts and Use",
        description:
          "Get a basic understanding of how computer memory works and how Java manages it. Knowing about memory will help you write better, more efficient programs.",
      },
      {
        title: "Memory Allocation: Behind the Scenes in Java",
        description:
          "Dive deeper into how Java handles memory allocation. Learn about the stack, heap, and how Java allocates and deallocates memory for your programs.",
      },
      {
        title: "Exception Handling in Java",
        description:
          "Find out how to handle errors and exceptions in your code using 'try', 'catch', and 'finally'. This makes your programs more robust and less likely to crash.",
      },
      {
        title: "File Handling and I/O in Java",
        description:
          "Learn how to read from and write to files in Java. Understand different types of input and output operations to manage data in your applications.",
      },
      {
        title: "Java Collections Framework",
        description:
          "Explore Java's Collections Framework, which includes powerful tools like lists, sets, and maps. These help you manage groups of objects efficiently in your programs.",
      },
      {
        title: "Java Generics: Writing Flexible Code",
        description:
          "Discover how generics let you write flexible, type-safe code that works with different data types. This reduces code duplication and makes your programs more robust.",
      },
      {
        title: "Concurrency in Java: Threads and Synchronization",
        description:
          "Learn about multithreading and how to manage multiple threads running at the same time. Synchronization helps you control these threads to avoid conflicts in your programs.",
      },
      {
        title: "Lambda Expressions and Streams API",
        description:
          "Understand how lambda expressions and the Streams API enable you to write cleaner, more efficient code. These features support functional-style programming in Java.",
      },
      {
        title: "Debugging and Testing Java Programs",
        description:
          "Discover techniques for debugging and testing your Java code. Learn about tools and frameworks like JUnit to ensure your programs are reliable and error-free.",
      },
      {
        title: "Working with Databases: JDBC",
        description:
          "Learn how to connect your Java programs to databases using JDBC. You'll be able to execute queries and manage data stored in relational databases.",
      },
      {
        title: "Introduction to Java Frameworks: Spring Basics",
        description:
          "Get an introduction to the Spring framework. Learn about its key features, like dependency injection and MVC architecture, and how to build simple web applications.",
      },
      {
        title: "Spring Framework: Advanced Concepts",
        description:
          "Get to know advanced concepts of Spring framework, including caching, JPA and scheduling.",
      },
    ],
  },

  {
    enabled: false,
    id: 5,
    image: "cloud.jpeg",
    title: "Cloud Adventures: Discovering the Magic of the Internet!",
    brief:
      "This engaging course for kids explores the wonders of cloud computing. Students will learn how the cloud stores data and powers online activities, all through fun and interactive lessons that make complex concepts easy to understand and apply.",
    description: `"Cloud Adventures: Discovering the Magic of the Internet!" is an exciting and easy-to-understand course designed to Introduction to kids to the world of cloud computing. Through fun, interactive lessons, students will learn how the cloud powers their favorite apps and games, stores their photos and videos, and connects them with friends and family online. They'll explore how to use cloud services safely and understand the basics of data storage, sharing, and collaboration. This course turns complex concepts into playful experiences, helping young learners grasp how the internet works and how they can use it creatively and responsibly.`,
  },
];

export type ICourse = {
  title?: string;
  brief?: string;
  description?: string;
  id: number;
  image?: string;
  courseFee?: string;
  enabled?: boolean;
  chapters?: IChapter[];
};

export type IChapter = {
  title: string;
  description?: string;
};
