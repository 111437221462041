export const colorPalette = {
    'silver_lake_blue': {
        DEFAULT: '#758db0',
        100: '#151c25',

        200: '#2a374a',
        300: '#40536f',
        400: '#556e94',
        500: '#758db0',
        600: '#90a3bf',
        700: '#abbacf',
        800: '#c7d1df',
        900: '#e3e8ef'
    },
    'lapis_lazuli': {
        DEFAULT: '#285ca4',
        100: '#081221',
        200: '#102542',
        300: '#183763',
        400: '#204983',
        500: '#285ca4',
        600: '#3a78cf',
        700: '#6b9adb',
        800: '#9cbce7',
        900: '#ceddf3'
    },
    'glaucous': {
        DEFAULT: '#627798',
        100: '#13181e',
        200: '#272f3d',
        300: '#3a475b',
        400: '#4e5f7a',
        500: '#627798',
        600: '#8092ae',
        700: '#a0adc2',
        800: '#c0c8d6',
        900: '#dfe4eb'
    },
    'cool_gray': {
        DEFAULT: '#7986a6',
        100: '#171a22',
        200: '#2e3445',
        300: '#454e67',
        400: '#5b6889',
        500: '#7986a6',
        600: '#939db7',
        700: '#aeb6c9',
        800: '#c9cedb',
        900: '#e4e7ed'
    }
}