import React from "react";
import './carina.css'
import { LearnHeader } from "../Header/LearnHeader";
import { FooterLearn } from "../Footer/FooterLearn";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";


export const CoderCarina = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Splay technologies learning centre - Coders: All about me: Carina</title>
                </Helmet>
            <LearnHeader />
            <Container className="bg-black">

                <h1>
                    My name is Carina
                    <h2>&#128513;</h2>
                </h1>
                <h2 className="txt-red">
                    My Hobbies are:</h2>
                <li> Badminton </li>
                <li> Talking to Friends </li>

                <li> Karate </li>
                <li> reading </li>
                <h2 className="txt-purple">
                    My favourite players: </h2>
                <li>Saina Nehwal </li>
                <li> PV Sindhu </li>

                <h2 className="txt-green"> My favourite Subjects</h2>
                <li> Mathematics </li>
                <li> Health and PE</li>
                <li> Music</li>
                <li> English</li>

                <h2 className="txt-orange">My favourite Foods:</h2>
                <li> Butter Chicken</li>
                <li> Pizza</li>
                <li> burgers</li>
                <li> Pasta</li>
                <h2 className="txt-pink">
                    My achievements:</h2>
                <li> Trophies in Dance </li>
                <li> Medal in badminton </li>
                <li> School Acheivements </li>
                <li> Logic (math extention)</li>
                <li> Karate belts </li>
                <li> swimming levels </li>

                <div>
                    <p> I like to spend my weekends with my family.</p>
                    <p>   Some of my favourite activites to do with my family are playing board games and going shopping. </p>
                    <p> Its great when I can have my family home at the weekends.</p>

                    <img
                        src="https://splaytech.com.au/blue-moon-rose.jpeg"
                        width="400px" height="400px" />

                </div>
            </Container>
            <FooterLearn />
        </>
    )
}